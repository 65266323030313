#root {
    --primary: #096eff;
    --link_color: #096eff;
    --success_color: #4caf50;
    --error_color: #f44336;
    --warning_color: #ff9800;
    --info_color: #096eff;
    --primary-rgb: 9, 110, 255;
    --rounded: 6px;
    --rounded-xs: 2px;
    --rounded-sm: 4px;
    --rounded-md: 8px;
    --rounded-lg: 12px;
    --rounded-xl: 20px;
    --window-height: 100vh;
}

.database_item {
    align-items: flex-start;
    border-radius: var(--rounded-md);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: flex;
    height: 100%;
    overflow: hidden;
    padding: 20px;
    position: relative;
    width: 100%;
}

.database_item-title {
    color: #1e2736;
    font-size: 17px;
    font-weight: 500;
    width: 100%;
    word-break: break-word;
}
