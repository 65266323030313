.default__header {
    position: fixed;
    z-index: 10;
}

.default__header-hr {
    background: linear-gradient(rgba(9, 30, 66, 0.13), rgba(9, 30, 66, 0.13) 1px, rgba(9, 30, 66, 0.08) 0, rgba(9, 30, 66, 0) 4px);
    content: '';
    height: 4px;
    left: 10px;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 8;
}

.default__header-logo {
    display: flex;
    align-items: center;
    height: 100%;
}

.header__logo-pc {
    height: 32px;
    margin: 14px;
}

.default-btn {
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 17px;
    margin: 16px;
    background-color: #f3f3f4;
    border: 1px solid #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    padding: 8px;
}

.default-btn:hover {
    background-color: #e4e4e7;
    cursor: pointer;
}

.default-menu {
    color: #7a869a;
    height: 30px;
    margin-top: 20px;
    padding-left: 28px;
    border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
}

.default-sider {
    z-index: 10;
    margin-top: 64px;
}

.default-sider::after {
    background: transparent;
    border-top: 1px solid var(--border);
    border-top-left-radius: 20px;
    box-shadow: -6px -6px #fff;
    content: '';
    height: 30px;
    position: absolute;
    right: -30px;
    top: 0;
    width: 30px;
    z-index: 10;
}

.default-content {
    margin-left: 230px;
    transition: all 0.2s, background 0s;
    margin-top: 64px;
}

.default-content.active {
    margin-left: 84px;
}

.menu-sidebar.ant-menu-light .ant-menu-submenu-arrow,
.menu-sidebar.ant-menu-light svg,
.menu-sidebar.ant-menu-light .ant-menu-title-content {
    color: #7a869a !important;
    font-weight: 600;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-menu .ant-menu-item,
:where(.css-dev-only-do-not-override-mzwlov).ant-menu .ant-menu-submenu,
:where(.css-dev-only-do-not-override-mzwlov).ant-menu .ant-menu-submenu-title {
    border-radius: 8;
}

.menu-sidebar .ant-menu-submenu-selected svg,
.menu-sidebar .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-title-content,
.menu-sidebar .ant-menu-submenu-selected .ant-menu-submenu-arrow,
.menu-sidebar .ant-menu-item-selected svg,
.menu-sidebar .ant-menu-item-selected .ant-menu-title-content {
    color: #096eff !important;
}

.ant-menu-item:hover:not(.ant-menu-item-selected) svg,
.ant-menu-item:hover:not(.ant-menu-item-selected) .ant-menu-title-content {
    color: rgba(0, 0, 0, 0.88) !important;
}

@media (max-width: 550px) {
    .default-content {
        margin-left: 0;
    }

    .default__header-logo a {
        display: none;
    }
}
