* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}

::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 3px;
}

::-webkit-scrollbar-track {
    background: #f2f2f2;
}

/* Antd */
.ant-space-item {
    width: 100%;
}

.ant-pagination-options {
    display: none !important;
}

.ant-pagination-item {
    border-color: #1677ff !important;
}

.ant-pagination-prev button,
.ant-pagination-next button {
    color: #1677ff !important;
}

.ant-pagination-disabled button {
    color: #00000040 !important;
}

.ant-pagination-item-active {
    background-color: #1677ff !important;
}

.ant-pagination-item-active a {
    color: #fff !important;
}

.ant-pagination-next,
.ant-pagination-prev,
.ant-pagination-item {
    min-width: 30px !important;
    height: 30px !important;
}

.ant-image-mask-info {
    display: none !important;
}

.ant-image-mask:hover {
    opacity: 0 !important;
}

/* Header */
.header-drawer .ant-drawer-body {
    padding: 0;
}

.header-drawer .ant-drawer-close {
    align-items: center;
    border: 1px solid #95959d;
    border-radius: 8px;
    color: #95959d;
    display: flex;
    height: 35px;
    justify-content: center;
    margin-right: 0;
    position: absolute;
    right: 15px;
    top: 19px;
    width: 35px;
    z-index: 99;
}

.header__logo-mobile {
    width: 140px;
}

.ant-tabs-tab-active .text-subtitle {
    color: #1677ff !important;
    text-shadow: 0 0 0.25px currentcolor !important;
}

.ant-table-row-expand-icon-cell {
    display: none !important;
}

.api-detail .copy {
    position: relative;
}

.api-detail pre {
    display: block;
    color: #212529;
}

.api-detail .text-copy {
    display: inline-block;
    padding: 10px;
    min-width: 100%;
    background: #eff6ff;
    border-radius: 3px;
    color: #008000;
    font-size: inherit;
    word-break: normal;

    white-space: pre-wrap;
    word-wrap: break-word;
    max-width: 100%;
    overflow-x: auto;
}

.api-detail .btn-copy {
    position: absolute;
    top: 6px;
    right: 6px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
}

.api-detail .copy:hover .btn-copy {
    opacity: 1;
    visibility: visible;
}

.api-detail .copy svg {
    color: #7a869a;
}

.api-detail .btn-copy:hover svg {
    color: #4096ff;
}

.ant-table-body {
    scrollbar-color: auto !important;
    scrollbar-width: auto;
}

table td,
table th {
    white-space: nowrap;
}

.responsive-flex {
    display: flex;
    flex-wrap: wrap;
}

.responsive-item {
    flex: initial;
}

.cart-container {
    min-height: calc(-171px + 100vh);
}

.px-xs-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

.charging-total {
    transform: translateX(14%);
}

@media (max-width: 740px) {
    .ant-table-content {
        overflow-x: auto !important;
    }

    .header-drawer.ant-drawer-content .ant-drawer-header {
        padding: 16px 60px;
    }

    .responsive-item {
        width: 100%;
        justify-content: start;
    }

    .responsive-item + .responsive-item {
        margin-top: 16px;
    }

    .responsive-item .ant-input-outlined {
        width: 100% !important;
        margin-left: 0 !important;
    }

    .responsive-item .responsive-child {
        width: 100% !important;
        margin: 0 !important;
    }

    .cart-container {
        min-height: auto;
    }

    .xs-mt-3 {
        margin-top: 16px;
        width: 50px;
    }

    .px-xs-4 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .charging-total {
        transform: translateX(0);
    }

    .home-col {
        min-width: 100%;
    }
}
